<template>
  <div
    v-if="editedItem"
  >
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-ticket</v-icon>
      <v-toolbar-title>
        {{ $t(isNew ? 'common.create' : 'common.edit', locale) }} {{ $t('booking.activity', locale).toLowerCase() }}
      </v-toolbar-title>
    </v-toolbar>
    <v-stepper
      v-if="stepsFiltered"
      v-model="pos"
      vertical
    >
      <v-layout wrap>
        <v-flex xs12 
        >
          <step-item
            v-for="(item, index) of stepsFiltered"
            :first="index === 0"
            :last="((index+1) === stepsFiltered.length)"
            :skip="item.skip"
            :editedItem="editedItem"
            :step="index+1"
            :item="item"
            :key="index"
            :pos="pos"
            :locale="locale"
            :onChangeStep="handleChangeStep"
            :onChangeEditedItem="handleChangeEditedItem"
            :onSave="handleSave"
            :onCancel="handleCancel"
          />
        </v-flex>
        <v-flex xs12 style="text-align: right">
          <v-btn 
            text 
            @click="handleCancel" 
            style="margin-right:10px"
          >
            {{$t('common.close', locale)}}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-stepper>
  </div>
</template>
<script>
import api from '@/services/api'
import { defaultActivity } from '@/constants/booking'
import StepItem from './StepItem'
export default {
  components: {
    StepItem,
  },
  props: {
    editedID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    editedItem: null,
    pos: 1, // 6,// 1,
    stepsFiltered: null,
    steps: [
    {
      title: 'titleAndType',
      form: 'scheduleType',
    }, 
    {
      title: 'detail',
      subtitle:'detailSubtitle',
      form: 'detail',
    }, 
    {
      title: 'image',
      subtitle:'imageSubtitle',
      form: 'image'
    },
    {
      title: 'location',
      subtitle:'locationSubtitle',
      form: 'location'
    },
    /*{
      title: 'reservation',
      form: 'reservation',
      workspaceReservation: true,
    },*/
    {
      title: 'schedule',
      subtitle:'scheduleSubtitle',
      form: 'schedule',
      pos: 2,
      skip: true,
    },
    {
      title: 'formFields',
      subtitle:'formFieldsSubtitle',
      form: 'formFields',
    },
    ]
  }),
  computed: {
    isNew () {
      return this.editedID === 'new'
    },
    workspaceID () {
      return this.$store.state.app.bookingWorkspace
    },
    workspaceAllowReservation () {
      return this.$store.state.app.bookingWorkspaceAllowReservation
    },
  },
  mounted () {
    this.stepsFiltered = this.steps//.filter(x => this.workspaceAllowReservation || !x.workspaceReservation)
    this.editedItem = defaultActivity
  },
  methods: {
    handleChangeStep (v) {
      this.pos += v 
    },
    handleChangeEditedItem (v) {
      this.editedItem = v
    },
    handleSave () {
      this.editedItem.WorkspaceID = this.workspaceID
      if (this.editedItem.Schedule) {
        this.editedItem.Schedule.SeatsType = this.editedItem.Schedule.SeatsType ? this.editedItem.Schedule.SeatsType : this.editedItem.SeatsType
      }
      api.addItem ('booking', 'v1/private/activities/steps', this.editedItem)
        .then((response) => {
          this.onClose('add', response.data)
        })
    },
    handleCancel () {
      this.onClose()
    },
  },
}
</script>
