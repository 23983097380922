<template>
  <v-layout wrap
    :key="bookingWorkspace"
  >
    <v-flex xs6
      class="activities-index"
    >
      <v-card
        elevation="0"
      >
        <v-card-text class="card-text">
          <list
            :locale="locale"
            :onEdit="handleEditItem"
            :updateData="updateData"
          />
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs6
      style="padding:20px 20px 20px 10px;"
    >
      <v-card
        style="height:calc(100vh - 104px);overflow-y: auto;overflow-x: hidden;scrollbar-width: thin !important;"
        elevation="0"
      >
        <v-card-text>
          <no-edited
            v-if="!editedID"
            :locale="locale"
            :onAdd="handleAddItem"
          />
          <form-activity-stepper
            v-else-if="isAssisted"
            :editedID="editedID"
            :locale="locale"
            :onClose="handleClose"
          />
          <form-activity
            v-else
            :editedID="editedID"
            :locale="locale"
            :onClose="handleClose"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import List from './List'
import NoEdited from './NoEdited'
import FormActivity from './formActivity/Index'
import FormActivityStepper from './formActivity/formActivityStepper/Index'
export default {
  components: {
    List,
    NoEdited,
    FormActivity,
    FormActivityStepper,
  },
  data: () => ({
    startIndex: 0,
    itemsPerPage: 5,
    search: null,
    isAssisted: false,
    editedID: null,
    updateData: null,
  }),
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace']),
    paramID () {
      return this.$route.params.id
    },
  },
  mounted() {
    if (this.paramID) this.handleEditItem(this.paramID)
  },
  methods: {
    removeParamID () {
      if (this.paramID) window.location.href = window.location.href.split(`/${this.paramID}`)[0]
    },
    handleEditItem (v) {
      this.handleClose()
      if (v !== this.paramID) this.removeParamID()
      this.editedID = v
    },
    handleAddItem (assisted) {
      this.removeParamID()
      this.isAssisted = assisted
      this.editedID = 'new'
    },
    handleClose (action, data) {
      this.removeParamID()
      this.updateData ={
        action,
        data,
        LMD: new Date().getTime()
      }
      this.editedID = null
      this.isAssisted = false
    },
  }
}
</script>
<style scoped>
.activities-index {
  padding: 20px 10px 20px 20px;
  height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none !important;
}
.activities-index .card-text {
  padding: 0;
}
</style>

