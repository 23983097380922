<template>
  <v-card
    v-if="pos"
    color="grey lighten-1"
    class="mb-12"
    min-height="150px"
    style="padding: 10px"
  >
      <v-layout wrap>
        <v-flex xs12>
          <events-list
            :events="events"
            :locale="locale"
            :onEdit="handleEdit"
            :onDelete="handleDelete"
          />
        </v-flex>
        <v-flex xs12 style="text-align: right;margin-top: 20px;padding: 10px;">
          <activity-event-form
            :editedItem="editedItem"
            :onSave="handleSave"
            :onClose="handleClose"
            :locale="locale"
            :dialog="showDialog"
          />
          <v-btn block :elevation="0" color="primary" @click="handleAdd">
            {{ $t('common.add', locale) }}
          </v-btn>
        </v-flex>
      </v-layout>
  </v-card>
</template>
<script>
import utils from '@/services/utils'
import ActivityEventForm from '@/components/ActivityEventForm'
import EventsList from './EventsList'
export default {
  name: 'ScheduleSpecific',
  components: {
    EventsList,
    ActivityEventForm,
  },
  props: {
    pos: {
      type: Number,
      required: true,
    },
    schedule: {
      type: [Array, Object],
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    showDialog: false,
    editedItem: null,
    events: []
  }),
  mounted () {
  },
  methods: {
    handleClose () {
      this.editedItem = null
      this.showDialog = false
    },
    handleEdit (v) {
      this.editedItem = JSON.parse(JSON.stringify(this.events.filter(x => x.ID === v.ID).shift()))
      this.showDialog = true
    },
    handleAdd () {
      this.editedItem = {
        ID: utils.getNewID(),
        Date: utils.checkDate(new Date()),
        Time: 480,
        Duration: 90,
        IsNew: true,
      }
      this.showDialog = true
    },
    handleSave (v) {
      this.events = this.events.filter(x => x.ID !== v.ID)
      this.events.push(v)
      this.handleChangeIsModified()
      this.handleClose()
    },
    handleDelete (v) {
      this.events = this.events.filter(x => x.ID !== v)
      this.handleChangeIsModified()
    },
    handleChangeIsModified () {
      this.onChange(this.events)
    },
  },
}
</script>

